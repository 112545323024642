/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
} */
/* .register-steps {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7fb;
} */


/* .register-steps .container .steps {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.register-steps .steps .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  color: #999;
  font-size: 22px;
  font-weight: 500;
  border-radius: 20%;
  background: #fff;
  border: 4px solid #e0e0e0;
  transition: all 200ms ease;
  transition-delay: 0s;
}
.register-steps .steps p{
    align-items: center;
    color:blue;
}
.register-steps .steps .circle.active {
  transition-delay: 100ms;
  border-color: #4070f4;
  color: #4070f4;
}
.register-steps .steps .progress-bar {
  position: absolute;
  height: 4px;
  width: 20%;
  background: blue;
  color:blue;
  z-index: -1;
}
.register-steps .progress-bar .indicator {
  position: absolute;
  height: 100%;
  width: 20%;
  color: red;
  background: #4070f4;
  transition: all 300ms ease;
}
.register-steps .container .buttons {
  display: flex;
  gap: 20px;
}
.register-steps .buttons button {
  padding: 8px 25px;
  background: #4070f4;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  transition: all 200ms linear;
}
.register-steps .buttons button:active {
  transform: scale(0.97);
}
.register-steps .buttons button:disabled {
  background: #87a5f8;
  cursor: not-allowed;
} */

.email-verification-form{
    /* position:absolute;
    top: 50%;
    left: 50%; */
    margin-top: 3%;
    margin-bottom: 10%;
    /* width: 50%; */
}

.email-verification input#userEmail{
    /* height: 5vw; */
    font-size: 1rem;
    font-weight: 600;
    color:var(--text-gray2);
    /* width: 20vw; */
    width:100%;
    height: 100%;

}

.email-verification input:focus{
    outline: 0px !important;
    
    
}
div.email-verifcation-button {
    margin-left: .35rem;
    margin-top: 0rem;
}

div.email-verifcation-button i{
    font-size: 1rem;
    color: var(--magic-green);
}
.email-verification-button {
    background-color: var(--primary-color);
    color: white;
    font-weight: 600;
    height: 100%;;
    border-width: .15rem;
    font-size: 1.rem;
    border-color: var(--primary-color);
}
.email-verification-button:hover {
    color: var(--primary-color);
    background-color: white;
}

.otp-label{
    font-size: 1rem;
}

.invalid-feedback-2{
    color: red;
}
/* 


/* otp popup */
.card {
    width: 400px;
    border: none;
    height: 350px;
    box-shadow: 0px 5px 20px 0px #d2dae3;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center
}

.card h6 {
    color: var(--primary-color);
    font-size: 20px
}

.inputs input {
    width: 40px;
    height: 40px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.card-2 {
    background-color: #fff;
    padding: 10px;
    width: 350px;
    height: 100px;
    bottom: -50px;
    left: 20px;
    position: absolute;
    border-radius: 5px
}

.card-2 .content {
    margin-top: 50px
}

.card-2 .content a {
    color: var(--magic-green)
}

.form-control:focus {
    box-shadow: none;
    border: 2px solid var(--primary-color);
}



.validate {
    background-color: var(--magic-green);
    color: white;
    font-weight: 600;
    font-size: 1rem;
    border-color: var(--magic-green);
}
.validate:hover {
    color: var(--primary-color);
    background-color: white;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}
/* body {
  font-family: Montserrat, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  display: grid;
  place-items: center;
  min-height: 100vh;
  background-color: #0b4eb3;
} */


.register-steps .container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* gap: 40px; */
    max-width: 80%;
    /* width: 80%; */
  }
  
  

.width-50 {
  width: 50%;
}
.ml-auto {
  margin-left: auto;
}
.text-center {
  text-align: center;
}
.progressbar {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  counter-reset: step;
  margin: 2rem 0 4rem;
}
.progressbar::before,
.progress {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 50%;
  background-color: #dcdcdc;
  z-index: 1;
}
/* .progress {
  background-color: rgb(0 128 0);
  width: 0%;
  transition: 0.3s;
} */
.progressMember {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 60%;
  background-color: #dcdcdc;
  z-index: 1;
}
/* .progressMember {
  background-color: rgb(0 128 0);
  width: 0%;
  transition: 0.3s;
} */
.progress-step {
  width: 2.1875rem;
  height: 2.1875rem;
  background-color: #dcdcdc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.progress-step::before {
  counter-increment: step;
  content: counter(step);
}
.progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 0.85rem;
  color: #666;
}
.progress-step-active {
  background-color: var(--primary-color);
  color: #f3f3f3;
}





@keyframes animate {
  from {
    transform: scale(1, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}



@media (min-width: 768px) {
  .register-email-input > input {
    width: 21rem !important;
  }
}

@media (max-width: 767px) {
  /* mobile */
  .register-email-input > input {
    width: 21rem !important;
  }
  .register-email-input-container{
    flex-direction: column !important;
    display: flex !important;
    gap: 1rem;
  }
}
