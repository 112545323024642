.tenant-owner-carousel-btn{
    text-align: center;
    width:100%;
    height: 2em;
    font-size: 1.1em;
    font-weight: 400;
    text-decoration: none;
    border-radius:0.2em ;
    color: white;
    background-color: var(--primary-color);
    padding: 0.2em;
}

