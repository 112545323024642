.productpage{
    margin-top: 5%;
     background-color:#FAFAFA;

}

.productpage .productconatiner {
    width: 100%;
    padding-top: 1.5%;
}
.productpage .productdetails{
   background-color: var(--white-color);
}

.productdetails .productheading{
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
}

.productheading .tiitle h3{
    font-weight: 400;
    font-size: 140%;

}

.productdetails .lastsection{
    color: #989898;
    padding-bottom: 3%;
    text-transform: capitalize;
    font-size: 90%;
}

.productdetails .lastsection .inline-block{
    display: inline-block;
}

.productoverview{
margin-bottom: 2rem;
}

/* mobile  */
@media (max-width: 767px) {
    .productoverview{
        margin-bottom: 5.5rem;
        }
}

.productoverview .propertycard{
    width: 100%;
    margin-top: 0.4%;
    padding: 3.4%;
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: none;

}

.content .rentsecurity{
    position: relative;
    margin-bottom: 5px;
    padding-bottom: 20px;
   
    display: flex;
    flex-direction: column;
}

.rentsecurity .heading{
    font-size: 100%;
    margin-bottom: 0.5%;
    font-weight: 700;
}

.propertycard .chargesdetails{
    border: 1px solid #ddd;
   display:block;
    min-height: 5em;
    box-sizing: border-box; /* Ensures padding and borders are included in the element's width and height */
}
/* desktop */
/* Prevent double borders on adjacent columns */
.propertycard .chargesdetails:not(:last-child) {
    border-right: none;
}

.propertycard .chargesdetails:nth-child(4n) {
    border-right: 1px solid #ddd; /* add right border last item (4)*/
}

/* Remove bottom border from all except last row */
.propertycard .chargesdetails:not(:nth-last-child(-n + 4)) {
    border-bottom: none;
}

/* mobile  */
@media (max-width: 767px) {

    /* Prevent double borders on adjacent columns */
    .propertycard .chargesdetails:not(:last-child) {
        border-right: none;
    }

    .propertycard .chargesdetails:nth-child(2n) {
        border-right: 1px solid #ddd;  /* add right border last item (2)*/
    }

    /* Remove bottom border from all except last row */
    .propertycard .chargesdetails:not(:nth-last-child(-n + 2)) {
        border-bottom: none;
    }
}

.chargesdetails .labelheading {
    font-size: 0.875rem;
    color: #989898;
    font-weight: 400;
    padding-top: 0.5em;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.chargesdetails .datashow {
    color:#282828;
    font-weight: 500;
    font-size: 0.875rem;
    padding:0.5em;
    text-transform: capitalize;
}
/* .datashow .rent{
    color: green;
    font-weight: 450;
    font-size: 100%;
} */
/* .propertycard .othercharges{
   
    width: 100%;
} */

 

/* .othercharges .rent{
    padding-left: 2;
    border-right: 1px solid #ddd;
}
.othercharges .rent .label{
    font-size: 80%;
    color: #989898;
    font-weight: 400
}
.othercharges .rent .roomTypeRent{
color:#282828;
font-weight: 600;
font-size: 140%;
}   
.rent .depositimage{
    height: 10%;
    width:10%;
} */


.productpage .heading{
    font-size: 18px;
    padding-bottom: 1%;
    color: #282828;
    font-weight: 500;
}

.rules ul li svg {
    position: absolute;
    width: 1rem;
    left: 0;
    top: 10px;
}

.houseProperties .propertyName a{
   color:var(--primary-color);
   font-weight: 400;
}





.owneramenities .icon-class-active {
    filter: var(--primary-filter);
    max-width: 70%;
    height: 30%;

    border-color: none;
  
  }
  
 
  
  .owneramenities p.icon-class-text {
    color: #989898;
    font-size:  90%;
    font-weight: 400
  }

  .product .ownerContactus{
    background-color: var(--white-color);
    position: fixed;
    width:370px;

  }

  .ownerContactus .contactusHeading span{
    font-weight: 400;
    font-size: 140%;
    color:#282828;
     text-align: center;
  }


  .description .descriptionContent{
    padding: 0% 7% 4% 7%;
  }

.product .contactimagesection{
    padding-top:7% ;
    text-align: center;
}
.contactimagesection .contactimg{
   /* height: 90%; */
   width:20%;
 border-radius: 0.25rem;
   
}
.product .title{
    font-weight: 400;
    font-size: 120%;
    text-align: center;
    text-transform: capitalize;

}
.title .subtext{
    font-weight: 200;
    font-size:80%;
    color: #989898;
   

}

.shedualAvilable .calender-title{
    font-weight: 400;
    font-size: 80%;
}
.shedualvisit  .visit-title{
    font-weight: 400;
    font-size: 80%;
}

.Otherdetails .subHeading{
    font-size: 90%;
    padding-bottom: 1%;
    color: #4a4a4a;
    font-weight: 500;
}

.Otherdetails .subdataText{
    font-size: 100%;
    color: black;
    font-weight: 400;
    text-transform: capitalize;

}
.rentsecurity .roomTypeName{
    font-size: 90%;
    padding-bottom: 1%;
    color: #4a4a4a;
    font-weight: 500;
    text-transform: capitalize;

}
.fixeedPstionmobile{
    position: fixed;
    width: 100%;
    bottom:0;
    left: -5px;
   background-color: white; 
}


 
