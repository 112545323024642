

.navbar .navbar-toggler {
    color: var(--primary-color);
}

i.navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(29, 73, 167, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.headingforgetpassword{
    font-weight: 500;
    color: var(--primary-color);
    font-size: 90%;

    text-align: right;
    display: inline-block;
}