
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* body {
  font-family: Montserrat, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  display: grid;
  place-items: center;
  min-height: 100vh;
  background-color: #0b4eb3;
} */

.form {
    width: clamp(320px, 30%, 430px);
    margin: 0 auto;
    border: none;
    border-radius: 10px !important;
    overflow: hidden;
    padding: 1.5rem;
    background-color: #fff;
    padding: 20px 30px
}

label {
    display: block;
    margin-bottom: 0.5rem
}

.group-inputs {
    position: relative
}

.group-inputs label {
    font-size: 13px;
    position: absolute;
    height: 19px;
    padding: 4px 7px;
    top: -14px;
    left: 10px;
    color: #a2a2a2;
    background-color: white
}
/* .group-inputs span {
  font-size: 13px;
  position: absolute;
  height: 19px; 
  padding: 4px 7px;
  top: -14px;
  left: 10px;
  color: #a2a2a2;
  background-color: white
} */



.group-inputs input {
    display: block;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    height: 50px
}
.group-inputs select {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 50px
}



@keyframes animate {
  from {
    transform: scale(1, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.welcome {
  height: 450px;
  width: 350px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome .content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}





@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
