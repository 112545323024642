
#banner-main-text {
  color: var(--primary-color);
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

#banner-text {
    color: var(--primary-color);
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 4rem;
}
.top-banner .loc-icon{
  background-color: white;
}
.top-banner .city-search-bar {
  height: 3.5rem;
  width:100%
}


/* p {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 1px;
} */

.city-search-bar div.lect-selector input.ant-input{
  /* border-radius: 0%; */
  /* border-top-left-radius: 0.4rem ;
  border-bottom-left-radius: 0.4rem ; */

  height: 100%;
  border-color: var(--primary-color);
}

.city-search-bar div.ant-select-selector .ant-select-selection-search-input{
  border-radius: 0%;
  border-top-left-radius: 0.4rem ;
  border-bottom-left-radius: 0.4rem ;
  height: 100%;
  border-color: var(--primary-color);
}

.city-search-bar div.my-ant-selector  {
  height: 100%;
}
.city-search-bar div.my-ant-selector .ant-select-selector{
  height: 100%;
}

/* .top-banner .city-search-bar .search-elements{
  border-color: var(--primary-color);
  border-width: 1px;
  background-color: white;
  
} */
.top-banner .location-icon {
  background-color: white;
  border-color: blue;
  border-width: 3px;

}


.top-banner .city-search-bar button.search-group-text{
  /* border-color: var(--primary-color);
  border-width: 1px; */
  border-top-right-radius: 0.4rem ;
  border-bottom-right-radius: 0.4rem ;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);

}
.toggler-tenant-owner{
  position: relative;
  margin-left: -8px;
  
}

@media  (max-width: 400px){
  #banner-main-text {
    color: var(--primary-color);
      font-size: 2rem;
      font-weight: 900;
      margin-bottom: 1rem;
  }
  
  #banner-text {
      color: var(--primary-color);
      font-size: 1rem;
      font-weight: 900;
      margin-bottom: 4rem;
  }
}

@media  (min-width: 1850px){
  .searchbar{
    flex: 0 0 50%; /* Equivalent to col-md-7 */
    max-width: 50%;  /* Equivalent to col-md-5 */
  }
}

.counter-section-desktop-center {
  justify-content: center;
}

/* Message icon color change */
.ant-message-info .anticon  {
  color: var(--primary-color) !important; /* Example background color */
}