.contentBySlug {
    margin-top: 6%;
     background-color:#FAFAFA;

}
.contentBySlug .title{
    padding-top: 3%;
}

.contentBySlug .contentdetails{
   background-color: var(--white-color);
   padding-left: 3%;
   padding-top: 2%;
   padding-bottom: 2%;
   color:var(--text-gray2);
   font-family: var(--secondry-font);
   font-size: 110%;
   line-height: 150%;
}

@media screen and (max-width:767px) {
    .contentBySlug {
        margin-top: 3.8rem;
    } 
}