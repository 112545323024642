.carddisplay {
    height: 6rem;
    width: 18rem;
    background-color: #f2f2f2;
}

.tablediv1 {
    height: 1.6rem;
    width: 18rem;
    background-color: #f2f2f2;
    border-radius: 2rem;
    margin-bottom: 1rem;
}

.tablediv2 {
    height: 1.4rem;
    width: 8rem;
    background-color: #f2f2f2;
    border-radius: 2rem;
    margin-bottom: 1rem;
}

.tablediv3 {
    height: 1.6rem;
    width: 18rem;
    background-color: #f2f2f2;
    border-radius: 2rem;
    margin-bottom: 1rem;
}
.tablediv4 {
    height: 1.4rem;
    width: 8rem;
    background-color: #f2f2f2;
    border-radius: 2rem;
    margin-bottom: 1rem;
}
.btndisplay{
    height: 3rem;
    width: 8rem;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
}
.tenantdiplay{
   padding-top: 2rem;
}
