/* src/SearchBar.css */
.search-bar-container {
  width: 100%;
  max-width: 600px;
  background: rgba(242, 242, 242, 0.7);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

}

.search-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  
}

/* only for desktop */
@media  (min-width: 768px){
  .search-bar-container {
    margin-left:2%;
  }
}

/* Make the tab titles bold and change the color */
.custom-tab {
  font-weight: bold;
  color: var(--black-color); /* Change to your desired color */
}

/* Optional: Change the color of the active tab */
.ant-tabs-tab-active .custom-tab {
  color: var(--primary-color); /* Change to your desired active tab color */
}

/* Override the active tab bar line */
.ant-tabs-ink-bar {
  background-color: var(--primary-color) !important;
}

/* Override the tab separator line color */
.ant-tabs-nav::before {
  border-bottom: 2px solid var(--white-new-color) !important; 
  opacity: 0.5;
}
