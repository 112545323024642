@media (max-width:767px) {

  .mobile-edit-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    text-align: center;
  }
   
}

.owner-img-carousel{
  max-height: 22rem;
  object-fit: contain;
  width: auto;
  height: auto;
  margin: 0 auto;
}
