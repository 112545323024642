.contactUs{
    margin-top: 5%;
    background-color:var(--fourth-color);
    /* background-image: url("/src/components/admin/pexels-max-rahubovskiy-7018406.jpg" ) */
}

.contactUs .contactcontainer{
    width: 100%;
    padding-top: 2.5%;
   
}
.contactUs  .contactdisplay{
    background-color: var(--white-color);
}

.contactUs .submotbutton{
    padding-bottom: 8%;
}

.contactUs .heading{
    color:var(--primary-color);
    padding-top: 2%;
}
.contactUs .verticalline{
    margin: 0px 0px 5px 50px;
      border-left: 1px solid rgb(77, 71, 71);
      height: 85%;
      font-weight: 450;
}
.contactUs .subContainer{
    margin-left: 10%;
    margin-right: 10%;
    background-color:var(--white-color);
    border-radius: 0.25rem; 

}
