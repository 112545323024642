@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse{ 
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}


:root {
  --spacing-small: 0.5rem; /* 8px */
  --spacing-medium: 1rem;  /* 16px */
  --spacing-large: 2rem;   /* 32px */

  --border-radius-small: 0.25rem; /* 4px */
  --border-radius-medium: 0.5rem; /* 8px */
}

.skeleton-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.skeleton-card {
  display: flex;
  align-items: center;
  padding: var(--spacing-medium);
  background-color: var(--white-color);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  width: 100%;
  margin: var(--spacing-medium) 0;
}

.skeleton-avatar {
  width: 5rem;
  height: 5rem;
  background-color: var(--text-gray);
  border-radius: var(--border-radius-small);
  margin-right: var(--spacing-medium);
}

.skeleton-text {
  flex-grow: 1;
}

.skeleton-line {
  height: 0.75rem;
  background-color: var(--text-gray);
  margin: 0.5rem 0;
  border-radius: var(--border-radius-small);
  width: 75%;
}

.skeleton-line.title {
  width: 50%;
  height: 1.2rem;
}

.skeleton-button {
  width: 6.25rem;
  height: 2.25rem;
  background-color: var(--text-gray);
  border-radius: var(--border-radius-medium);
  margin-left: var(--spacing-medium);
  margin-top: -10%;
}
