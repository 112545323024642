/* RegisterContact.css */

.styled-input-container {
  position: relative;
  margin-bottom: 1rem;
}

.styled-input-label {
  position: absolute;
  top: -0.625rem;
  left: 0.625rem;
  background-color: var(--white-color);
  padding: 0 0.313rem;
  font-size: 0.75rem;
  color: var(--text-gray);
  z-index: 40;
}

.styled-input {
  width: 100%;
  padding: .5rem;
  border: 0.063rem solid var(--text-gray);
  border-radius: 0.25rem;
  transition: border-color 0.3s;
}

.styled-input:focus, .styled-input:hover, .styled-input:active {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 .3px var(--primary-color) !important;
}

.ant-select-selector {
  height: 2.5rem !important;
  display: flex;
  align-items: center;
}

.ant-select {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0;
}

.style-button-modal-cont {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  justify-content: flex-end;
}

/* Change Select color */
.styled-input-container * .ant-select-selector:hover,
.styled-input-container * .ant-select-selector:focus,
.styled-input-container * .ant-select-focused .ant-select-selector,
.styled-input-container * .ant-select-open .ant-select-selector{
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 .3px var(--primary-color) !important;
}


/* Center modal title */
.modal-title {
  text-align: center;
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: 600; /* Optional: Adjust font weight as needed */
  margin-bottom: 1rem;
}

.style-privacy-text-modal-cont {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  justify-content: center;
}