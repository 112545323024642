.map-inputbox{
    /* overrided in registeradd.css */
    margin-top:6%;
    width:400px;
}

.no-map-inputbox{
    height: 100%;
    width: 100%;
}

/* search bar hover color */
.no-map-inputbox * .ant-input-affix-wrapper{
    border-color: var(--primary-color) !important; 
}

/* find button*/
.ant-btn:hover {
    opacity: 0.9 !important; /* Adjust the opacity on hover */
}  
.ant-btn {
    background-color : var(--primary-color) !important;
}