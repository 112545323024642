
*{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/



*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

a,
a:hover {
  text-decoration: none;
}

/* a:hover {
  color: var(--primary-color) !important;
} */

ul,
ol {
  margin: 0;
  padding: 0;
}


/*===== Variable Define =====*/
:root {
  --primary-color: #1D49A7;
  /* --secondry-color: #255CD2; */
  /* --secondry-color: #1D49A7; */
  --secondry-color:#F2F2F2;
  --fourth-color:#FAFAFA;
  --magic-green: #7ed957;
  --third-color: #f98169;
  --white-color: #fff;
  --white-new-color: #d1c4c4;
  --text-color: #555;
  --text-gray: #999;
  --text-gray-hover:#181818;
  --black-color: #000;
  --dark-grey:#1D2637;
  --text-gray2: #616161;
  --primary-font: 'Roboto', sans-serif;
  --secondry-font: 'Quicksand', sans-serif;
  --primary-filter: invert(20%) sepia(93%) saturate(1897%) hue-rotate(210deg) brightness(88%) contrast(87%);
  --text-size-fourteen: 0.875rem;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--primary-font);
  font-size: 100%;
  font-weight: 400;
}


/*
=========================================
    Custom Scrollbar
=========================================
*/ 

/* ::-webkit-scrollbar {
  width: 0.625rem;
} 
::-webkit-scrollbar-track {
  background: var(--white-color); 
} 
::-webkit-scrollbar-thumb {
  background:var(--primary-color); 
}  */

/*
==========================================
   Custom CSS Design
==========================================
*/

/* h1 {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 3rem;
  color: var(--secondry-color);
} */

/* h2 {
  font-weight: 700;
  font-size: 1.8rem;
  text-transform: capitalize;
  font-family: var(--secondry-font);
  color: var(--primary-color);
  line-height: 3rem;

} */
h2 {
  font-size: 1.5rem;
}
h4 {
  color: var(--secondry-color);
  font-family: var(--secondry-font);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

h5 {
  color: var(--primary-color);
  text-transform: capitalize;
  font-family: var(--secondry-font);
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}



.main-btn {
  display: inline-block;
  padding: 0.625rem 1.875rem;
  line-height: 1.5625rem;
  background-color: var(--primary-color);
  border: 0.1875rem solid var(--primary-color);
  color: var(--white-color);
  font-size: 0.9375rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: 0px 2px 10px -1px rgb(0 0 0 / 19%);
  -webkit-transition: all .4s ease-out 0s;
  -o-transition: all .4s ease-out 0s;
  -moz-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
  border-radius:0.4rem ;
}

.login-button {
  width: 7.5rem !important;
  text-align: center;
  height: 3.125rem !important;
  white-space: nowrap;
}

.white-btn {
  padding: 0.625rem 1.875rem;
  line-height: 25px;
  background-color: var(--white-color);
  border: 0.1875rem solid var(--white-color);
  color: var(--text-color);
  font-size: 0.9375rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: 0px 2px 10px -1px rgb(0 0 0 / 19%);
  -webkit-transition: all .4s ease-out 0s;
  -o-transition: all .4s ease-out 0s;
  -moz-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
  border-radius:0.4rem ;
}

.main-btn:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.white-btn:hover {
  background-color: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.wrapper {
  padding-top: 8.25rem;
  padding-bottom: 8.25rem;
}

/* .text-content {
  width: 70%;
  margin: auto;
} */

.counter-section h2,
.testimonial-section h2,
.book-food-text h2 {
  color: var(--text-gray2);
}

 /* .root {
  background-image:url("D:\Eksarthi(Complete)\react-tenent-search-master\public\images\pexels-karolina-grabowska-4590785 (1).jpg");
}  */


/*
==========================================
   Header Design
==========================================
*/

.navigation-wrap{
  /* position:fixed; */
  width:100%;
  left:0;
  z-index:1000;
  -webkit-transition:all 0.3s ease-out;
  transition:all 0.3s ease-out; 
  background-color: var(--white-color);
} 
.navigation-wrap .nav-item{
  padding:0 0.625rem;
  transition:all 200ms linear;
}
.navbar-toggler:focus{
  outline:unset;
  border:unset;
  box-shadow: none;
}
.nav-items-headers .nav-link{
  font-size:0.9375rem;
  font-weight: 600;
  text-transform: capitalize;
  color:var(--primary-color);
  letter-spacing: 1px;
}
.navigation-wrap .main-btn{
  padding:0.3125rem 1.4375rem;
  box-shadow: none;
  margin-left:0.625rem;
} 

/* Change navbar styling on scroll */

/* .navigation-wrap.scroll-on{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background: var(--white-color);
  -webkit-transition:all 0.3s ease-out;
  box-shadow: 0 0.125rem 1.75rem 0 rgb(0,0,0,0.09);
  transition:all .15s ease-in-out 0s;
}
  */


/*
==========================================
   top-banner Design
==========================================
*/
.top-banner{
  width:100%;
  background: url('../public/images/bg/pexels-burst-545034.jpg') no-repeat center;
  background-size:cover;
  padding:2rem 0 9.375rem;
}

/* .top-banner .search-group-text{
  background-color: var(--white-color);
} */

/* .my-select-container .ant-select .ant-select-selector {
  border-radius:100px;
} */

#basic-addon2{
  background-color: var(--primary-color);
  color: var(--white-color);
  font-weight: 600;
  border: 0.1875rem solid var(--primary-color);
}



.top-banner  .tab {
  display: flex;
  justify-content: space-between;
  background: var(--white-color);
  box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.08);
  border-radius:2rem ;
  -webkit-border-radius: 2.2rem;
  -moz-border-radius: 2.2rem;
   width: 100%; 
  height: 2.8rem;
  margin-left: 4.0rem;
  margin-bottom: 2rem;
  padding: 0.27rem 0.2rem;
}

.top-banner .tab .owner {
  text-align: center;
  /* width:20%; */
  height: 2em;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  border-radius:2.2rem ;
  color: var(--primary-color);
}
.top-banner .tab .tenant {
  text-align: center;
  /* width:20%; */
  height: 2em;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  border-radius:2.2rem ;
  color: var(--primary-color);

}

.top-banner .tab a{
  color: var(--primary-color);
}
.top-banner .tab .active a{
  color: var(--white-color);
}

.top-banner .tab .active {
  background:var(--primary-color);
  box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.19);
  height: 2.2rem;
  /* min-width: 6rem; */
  
}



 

/*
==========================================
  counter Design
==========================================
*/
.counter-section{
  background-color: var(--secondry-color);
  padding:2.5rem 0;
}
.counter-section p{
  color:var(--text-gray2);
  text-transform: uppercase;
}
.counter-section button{
  background-color:var(--primary-color);
  color:white;
}
.counter-section button:hover{
  background-color:white;
  color: var(--text-gray2);
  border-color: var(--primary-color);
  border-width: .2rem;
}
.counter-section a{
  color: var(--text-gray2);
  text-decoration: underline;
}
.counter-section a:hover{
  color: var(--primary-color);
  /* text-decoration: underline; */
}
.arrowcounter{
  color:var(--white-color)
}
 

/*
==========================================
 





section Design
==========================================
*/

.about-section{
  background: var(--white-color);

}
.about-section .card,
.about-section .card img{
  border-radius: 0.625rem;
  
}
.about-section .text-sec{
  padding-left:2rem;
 
}
 /*
==========================================
 explore world section Design
==========================================
*/
.explore-food .card{
  border:none;
  background-color: transparent;
  border-radius: 0.9375rem;
}
.explore-food .card img{
  border-radius: 0.9375rem;
  object-fit: cover;
}
.explore-food .card span{
  display:block;
  font-size: 1.5rem;
  font-weight: 700;
  color:var(--primary-color);
  padding-bottom:1rem;
  border-bottom:0.0625rem solid #eee;
}
.explore-food .card span del{
  color:var(--text-gray);
  margin-left:0.5rem;
}

/*
==========================================
 testimonial section Design
==========================================
*/
.testimonial-section{
  background-color: var(--secondry-color);
}
.testimonial-section .carousel-item{
  margin-top:3rem;
  padding:10rem 3.125rem;
  background-color:var(--white-color);
  border-radius: 0.9375rem;
  text-align: center;
} 
.testimonial-section .carousel-text img{
  max-width:5rem;
  border-radius: 50%;
  padding:0.3125rem;
}
.testimonial-section h3 {
  /* font-size: 1.5rem; */
  /* line-height: 2.0625rem; */
  color: var(--primary-color);
  font-weight: 900;
  /* font-style: italic; */
}
.testimonial-section .carousel-item .carousel-text p{
  font-size: 1.3125rem;
  line-height: 2.0625rem;
  /* padding:0 11% 0.625rem; */
  color: var(--primary-color);
  font-style: italic;
  font-weight: 500;
}
.testimonial-section .carousel-indicators{
  bottom:-2.8125rem;
}
.testimonial-section .carousel-indicators button{
  width:15px;
  height:15px;
  outline:none;
  border-radius: 50%;
  border:none;
  margin-right:1rem;
  opacity:0.2;
}
.testimonial-section .carousel-indicators button.active{
  opacity:1;
}


/*
==========================================
BrowseCity section Design
==========================================
*/

.BrowseCityMain {
  background-color: var(--white-color);
  

}
.BrowseCityMain h3 {
  color: var(--primary-color);
  font-weight: 900;
  padding-top: 1rem;
 
}
.BrowseCity ul {
  padding-bottom: 2rem;
  
}
.BrowseCity ul li a{
  font-weight: 400;
  font-size: 1rem;
  padding-left: 0.92rem;
}
.BrowseCity ul li a:hover{
  text-decoration: underline;
  font-weight: 500;
  color: var(--primary-color);
}

.BrowseCity .city-name{
  color: var(--text-gray2); 
}

.BrowseCity .country-name li{
  color: var(--text-gray2); 
  /* font-weight: 800; */
  /* font-size: 100px; */
}

/* Form Control */
.newslettar-main{
  width: 100%;
  background: url('../public/images/bg/pexels-dada-design-12277200.jpg') no-repeat center;
  background-size:cover;
  padding:5rem 0 9rem;
}

.newslettar-main h2 {
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: capitalize;
  font-family: var(--secondry-font);
  color: var(--primary-color);
  line-height: 3rem;

}

.newsletter{
  width:55%;
  margin:0 auto;
}
.newsletter .form-control{
  height:3.225rem;
  padding:0 1.25rem;
  font-size: 0.875rem; 
  width:100%;
  border:none;
  border-radius: 0;
  background:var(--white-color);
  border:0.1rem solid var(--text-gray);
  color:var(--black-color);
  font-weight: 700;
}
.newsletter .form-control:hover,
.newsletter .form-control:focus{
  outline:none;
  box-shadow: none;
  /* border-color:var(--primary-color); */
}

/*
==========================================
footer section Design
==========================================
*/
.footer{
  background: var(--secondry-color);
  margin-top: auto;
}


.footer .nav-item a{
  /* font-size:1rem; */
  color:var(--text-gray-hover);
  /* padding:0 0.6rem;
  margin-left: 1rem; */
}
.footer .nav-link:hover{
  color: var(--primary-color) !important;
}
.footer a i{
  font-size: 1.5rem;
  color:var(--text-gray2);
  padding:0 1rem;
}

.footer-social  i {
  color: var(--text-gray2)
}
.footer-social i:hover {
  color: var(--primary-color)
} 
.footer a i{
  font-size: 1.5rem;
  color:var(--text-gray2);
  padding:0 1rem;
}
.footer-copy p {
  color:var(--text-gray2);
}
/*--===============
Responsive Design===
==============- */
@media (max-width:1024px) {

    /* about section */
    
}

@media (max-width: 991px) {

    /*custom classes  */
    .text-content{
        width:100%;
    }
     

    /* navbar brand */
    .navigation-wrap .navbar-brand img{
        height:3.8125rem;
    }
    .navigation-wrap{
        text-align: center;
        background-color: var(--white-color);
    }
    .navigation-wrap .nav-link{
        line-height: 1.875rem;
    }
    /* Banner Design */
    .top-banner{
        padding:9.375rem 0 9.375rem;
    }

    /* carousel design */
    .testimonial-section .carousel-item .carousel-text p{
        padding:0;
    }

}

@media (max-width: 767px) {

    /* custom css */
    h1{
        font-size:2.8rem;
    }
    h2{ 
        font-size:2rem;
        line-height: 2.0625rem;
    }
     

    /* top banner */
    .top-banner{
        padding:10.875rem 0 9.375rem;
    }
    

    /* story section */
    .story-section{
        height:auto;
        padding:3rem 0;
    }
    

    /* carousel design */
    .testimonial-section .carousel-item{
        padding:11rem 3.125rem;
    }

    /* newslettar */
    .newsletter{
        width:75%;
    }
    .newsletter .main-btn{
        width:100%;
        margin-top:1rem;
    }

    /* footer link */
    .footer .footer-link,
    .footer a i{
        padding:0 0.7rem;
    }

}

@media (max-width:330px) {

    /* carousel design */
    .testimonial-section .carousel-item{
        padding:13rem 3.125rem;
    }
     
}


.loginmodal .vertical-line {
  border-left:0.1rem solid lightgray;
  height: 100%;
  position: absolute;
  left: 68%;
  margin-left: -3px;
  top: 0;
}
body{
  margin-top:20px;
  background:#FAFAFA;
}
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: rgb(70,145,247);
background: radial-gradient(circle, rgba(70,145,247,1) 0%, rgba(13,165,218,1) 100%);
}

.bg-c-green {
  background: rgb(99,189,129);
background: radial-gradient(circle, rgba(99,189,129,1) 0%, rgba(16,134,7,1) 100%);
}
.bg-c-yellow {
  background: rgb(208,200,35);
background: radial-gradient(circle, rgb(223, 213, 32) 0%, rgba(199,170,11,1) 100%);
}

.bg-c-pink {
  background: rgb(242,190,239);
  background: radial-gradient(circle, rgb(214, 157, 211) 0%, rgba(238,110,222,1) 100%);
}
.bg-c-red{
  background: rgb(228,87,51);
background: radial-gradient(circle, rgba(228,87,51,1) 0%, rgba(237,71,57,1) 100%);
}
.bg-c-purple{
  background: rgb(94,151,228);
background: radial-gradient(circle, rgba(94,151,228,1) 0%, rgba(99,72,250,1) 100%);
}
.bg-c-grey{
  background: rgb(57,56,54);
  background: linear-gradient(257deg, rgba(57,56,54,1) 0%, rgba(54,52,51,1) 50%, rgba(50,46,46,1) 100%);
}
/* .bg-c-plum{
  background: rgb(244,195,138);
  background: linear-gradient(257deg, rgba(244,195,138,1) 0%, rgba(241,213,188,1) 50%, rgba(250,250,250,1) 100%);
} */
.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
  border: none;
  margin-bottom: 20px;
  
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 5px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}






