    


.filter1 {
    padding: 0.5rem 0.5rem 0.4rem;
}

.seprationboder {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cfcece;
    margin: 1em 0;
    padding: 0;
}

.form-check-label-filter{
    font-size: var(--text-size-fourteen);
}

.accordion-button-filter{
    font-size: var(--text-size-fourteen);
}