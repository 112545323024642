.editprofile .profilejustification{
    background-color: var(--white-color);
    padding-left: 2%;
    padding-right: 2%;
}
.editprofile .heading{
   background-color: var(--white-color);
   font-weight: 500;
   color: var(--black-color);
}

.editprofile{
   background-color:var(--fourth-color);
   margin-top: 8%; 
}

.countrycode{
z-index: 40;
margin-left: .7rem;
}

.group-inputs-edit label {
   font-size: 13px;
   position: absolute;
   height: 19px;
   padding: 4px 7px;
   top: -14px;
   left: 10px;
   color: #a2a2a2;
   background-color: white
}
.group-inputs-edit {
   position: relative;
}