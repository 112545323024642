.share-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.25rem;
  margin: 0.25rem;
  margin-top: -0.5rem; /*Remove extra top space*/
}

.share-icon-Button {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: baseline;
  width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  background-color: white;
  border: none;
}

.share-icon-Button:hover{
  color: var(--primary-color);
}

.share-icon-size{
  font-size: 1.2em;
  width: 1.5rem;
  height: 1.5rem;
}