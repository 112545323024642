.messageList {
    margin-top: 7%;
    border: 1px solid #D3D3D3;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: .45rem;
}


.message-body{
    height: 63vh;
    overflow-y: auto;
    color: var(--text-gray2);
    overflow: hidden;
}

.message-input .rce-input-buttons{
   
}

.send-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    display: inline-flex;
    align-items: center;
    background-color: var(--primary-color);
    padding-left: 25%;
}
.send-button i{
    align-items: center;
    font-size: 20px;
    color: white
}

.load-history{
    width: 3rem;
    background-color: transparent;
    border: none;
}

.chat-list-body{
    height: 83.55vh;
    overflow-y: auto;
    border-right: 1px solid #D3D3D3;
    color: var(--text-gray2)
}
.chatInfoDiv{
    padding-top: .6rem;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: .6rem;
    color:var(--text-gray2);
}

@media  (max-width: 767px){
    .messageList {
        margin-top: 21%;
        border: 1px solid #D3D3D3;
        margin-left: 1%;
        margin-right: 1%;
        border-radius: .45rem;
    }
    .backButton{
        width: 3rem;
        background-color: transparent;
        border: none;
    }
    .backButton i{
        width: 1rem;
    }

    

    .chat-list-body{
        height: 80vh;
        overflow-y: auto;
        /* border-right: 1px solid #D3D3D3; */
        border-right:none;
        color: var(--text-gray2)
    }
}

/* .messageList {
    margin-top: 7%;
    border: 1px solid #D3D3D3;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: .45rem;
}


.message-body{
    height: 60vh;
    overflow-y: auto;
    color: var(--text-gray2);
    overflow: hidden;
}

.message-input .rce-input-buttons{
   
}

.send-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    display: inline-flex;
    align-items: center;
    background-color: var(--primary-color);
    padding-left: 25%;
}
.send-button i{
    align-items: center;
    font-size: 20px;
    color: white
}

.load-history{
    width: 3rem;
    background-color: transparent;
    border: none;
}

.chat-list-body{
    height: 81.5vh;
    overflow-y: auto;
    border-right: 1px solid #D3D3D3;
    color: var(--text-gray2)
}
.chatInfoDiv{
    padding-top: .6rem;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: .6rem;
    color:var(--text-gray2);
}

@media  (max-width: 767px){
    .messageList {
        margin-top: 21%;
        border: 1px solid #D3D3D3;
        margin-left: 1%;
        margin-right: 1%;
        border-radius: .45rem;
    }
    .backButton{
        width: 3rem;
        background-color: transparent;
        border: none;
    }
    .backButton i{
        width: 1rem;
    }

    

    .chat-list-body{
        height: 80vh;
        overflow-y: auto;
        border-right:none;
        color: var(--text-gray2)
    }
} */