
.city-search-input {
     height: 2.5rem;
   }


.registerADD{
  margin-top: 7%;
  background-color:#FAFAFA;
  padding-top: 0.5%; 
}

.registerADD .registeraddcontainer{
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 1%;
}

.registerADD .heading{
 font-family: Arial, Helvetica, sans-serif;
 font-weight: 500;
 font-size: 120%;
}
.registerADD .mainheading{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 130%;
  color: var(--primary-color);
}

.registerADD .owner {
     text-align: center;
     width:50%;
     min-height: 2em;
     font-size: 1.2rem;
     font-weight: 700;
     text-decoration: none;
     border-radius:2.2rem ;
     color: var(--primary-color);
     padding-top: .25rem;
     white-space: nowrap;
   }


  .registerADD .tenant {
     text-align: center;
     width:50%;
     min-height: 2em;
     font-size: 1.2rem;
     font-weight: 700;
     text-decoration: none;
     border-radius:2.2rem ;
     color: var(--primary-color);
     padding-top: .25rem;
     white-space: nowrap;
   }
   
.registerADD .active {
     background:var(--primary-color);
     box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.19);
     min-height: 2.2rem;
     min-width: 6rem;
     color: var(--white-color);
   }
   .registerADD  .toggler-tenant-owner  a {
    color: var(--primary-color);
   }
   .registerADD  .toggler-tenant-owner .active a {
    color: var(--white-color);
   }
.registerADD .tab {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     background: var(--white-color);
     box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.08);
     border-radius:2rem ;
     -webkit-border-radius: 2.2rem;
     -moz-border-radius: 2.2rem;
     min-height: 2.8rem;
     /* margin-left: .3rem; */
     margin-bottom: 2rem;
     padding: 0.27rem 0.2rem;
     min-width: 17.85rem;
   }

   .icon-class-checked {
     filter: var(--primary-filter);
     max-width: 40px;
     height: 70px;
     border-color: none;
   
   }
   
   .icon-class {
     filter: var(--text-gray2);
     max-width: 30px;
     height: 70px;
     border-color: none;
   }
   
   .amenties-main p.icon-class-text {
     color: var(--text-gray2);
     font-size: 1rem;
     font-weight: 300;
   }

 
   
   .amenties-main   p.icon-class-text-checked {
     color: var(--white-color);
     background-color: var(--primary-color);
     font-size: 1rem;
     font-weight: 500;
   
   }
  .amenties-main .form-check{
    width: max-content;
  }
  
  .amenties-main label.form-check{
    border: none;
  }


 
  .foodservice .foodservice-text{
      filter: var(--text-gray2);
      font-weight: 400;
      max-width: 10em;
      height: 4em;
      background-color: aliceblue;
      border-color: none;
      padding-top: 1.2em;
   
  }

  .foodservice .foodservice-text-checked{
    color: var(--white-color);
     background-color: var(--primary-color);
     font-size: 1.1vw;
     font-weight: 500;
 
}

.city-search-input div.ant-select-selector input.ant-input{
  /* border-radius: 0%; */
  /* border-top-left-radius: 0.4rem ;
  border-bottom-left-radius: 0.4rem ; */

  height: 50px;
  border: 1px solid #ccc
}

.city-search-input div.ant-select-selector .ant-select-selection-search-input{
  border-radius: 0.25rem;
  height: 50px;
  border: 1px solid #ccc
}

.city-search-input div.my-ant-selector  {
  height: 100%;
}
.city-search-input div.my-ant-selector .ant-select-selector{
  height: 100%;
}

.registeraddcontainer .map-inputbox {
  margin-top:1%;
  width: 100%;
  max-width: 600px;
}

label.select-label {
  cursor: pointer;
}
label.select-label input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}
label.select-label span {
  padding: 2%  7%;
    border: 2px solid var(--primary-color);
    display: inline-flex;
    color: var(--primary-color);
    border-radius:  0.25rem;
    height: 3.125rem;
    width:107%;
    align-items: center;
    justify-content: center;
    margin-left: 4%;
    margin-right: 4%;

}
label.select-label input:checked + span {
  border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--white-color);
}

/* .select-label {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: var(--white-color);
  border-width: 2px;
  height: 70px;
  display: inline-flex;
  align-items: center;

}



.select-label:hover{
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: var(--white-color);
}

.select-label.active:hover{

}


.select-label.active {

  border-color: var(--primary-color);
  color: var(--white-color);
  background-color: var(--primary-color);
  border-width: 2px;
  height: 70px;
  display: inline-flex;
  align-items: center;

} */


.success-message{
  margin-top: 5%;
}

.select-img-label{
  height: 148px;
  width: 152px;
}

