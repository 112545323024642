

.useradd{
    margin-top: 7%;
    background-color:rgb(250, 250, 250);
    padding-top: 0.5%; 
  }
  
  .useradd .useraddcontainer{
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 1%;
  }

  .adv-text{
    color: var(--primary-color);
  }
  
  .useradd .heading{
   font-family: Arial, Helvetica, sans-serif;
   font-weight: 600;
   font-size: 100%;
   text-decoration: underline;
   cursor: pointer;
  }

  .myradius {
    border: 1px solid #b9b4b4; 
    padding: 10px; 
   
    border-radius: 100px; 
    box-shadow: 0px 0px 5px rgba(0,0,0,1); /* For a slight shadow, making the border appear more elevated */
  }