.favoritelisting{
    background-color: #FAFAFA;
    margin-top: 6%;
  }
.favoritelisting .favoritelistingContainer{
    width: 100%;
    padding-top: 1.5%;
}
.favoritelisting  .headingTitle{
    padding-top: 2%;
    text-align: center;
  }
  .favoritelisting .headingcontent{
    color: (--dark-grey );
    font-weight: 500 ;
    font-size: 120%;
  }
  
  .listingdisplay .subheading{
    font-weight: 500;
    font-size: 100%;
  }
 /* .favoritelisting .liststyle ul li::before {
      content: "• ";
      color: #cfcece; 
    } */
  
   /* .favoritelisting .liststyle ul li{
      color: #212121;
      font-weight: 500;
      font-size: 0.9em;
      font-family: Arial, Helvetica, sans-serif;
    } */
  
   .favoritelisting .maincontent{
      color: var(--dark-grey);
      font-weight: 500 ;
      font-size: 100%;
    }
   .favoritelisting .maincontent:hover{
      color: var(--primary-color);
    } 
    .imagebg{
      background-color:var(--white-color);
      max-width: 110%;
      max-height: 10rem;
      object-fit: contain;
      border-radius: 4px;
    }
    .imagebgMobile{
      background-color:var(--white-color);
      max-width: 100%;
      max-height: 10rem;
      object-fit: contain;
      border-radius: 4px;
    }
   
    .headingcontent:hover{
      color:var(--primary-color)
    }
  
 .favoritelisting .sortinghead ul {
      list-style: none;
    }
    
   .favoritelisting .sortinghead li {
      display: inline-block;
      margin-right: 3%; 
  
    }
 .favoritelisting .tenantprofile{
   
  }
 .favoritelisting .sortinghead ul li a {
      color:var(--black-color);
      font-weight: 400;
      font-size: medium;
    }
 .favoritelisting .sortinghead ul li a:hover{
    color:var(--primary-color)
  }
 .favoritelisting .showresultheading{
      font-weight: 500;
      color: var(--black-color);
      padding-left: 1.4rem;
      padding-top: 0.5rem;
  }

 
  .filter .adjustment{
    margin-left: 1px;
    margin-right: 1px;
  }
  .filter .verticalLine{
    margin-top: 10px;
    margin-bottom: 5px;
      border-left: 2px solid gray;
      height: 45px;
      font-weight: 400;
     

  }
  .main .sortinghead .sortingSubHeading{
    font-weight: 600;
    font-size: 105%;
    color: var(--primary-color);
  }
  
  .span-address-bold {
    font-weight: bold;
    color: var(--primary-color);
  }

  .filter-text-h3{
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media screen and (max-width: 767px){
      .favoritelisting .favoritelistingContainer{
        padding-top: 2.5rem;
    }
  }

  .heading-text {
    text-align: center;
    font-size: 1.5rem;
    color: var(--dark-grey);
  }