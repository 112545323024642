
.call-button {
    width: 13.5%;
    object-fit: cover;
    cursor: pointer;
}

.call-button:hover {
    opacity: 0.7
}
