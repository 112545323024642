.forgetpassword{
    margin-top: 10%;
  } 

.forgetpassword-email-input > input {
  width: 21rem !important;
}

@media (max-width: 767px) {
  /* mobile */
  .forgetpassword{
    margin-top: 8rem;
  }
  .forgetpassword-email-input-container{
    flex-direction: column !important;
    display: flex !important;
    gap: 1rem;
  }
}