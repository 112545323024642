.distanceSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.distanceSearchInput {
  width: 80%;
  margin-bottom: 0.5rem;
  accent-color: var(--primary-color);
}

.seperationBorderMobile {
  display: block;
  height: 1px;
  width: 95%;
  border: 0;
  border-top: 1px solid #cfcece;
  margin: 1em 0;
  margin-right: 2.5%;
  margin-left: 2.5%;
  padding: 0;
}

.seperationBorder {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cfcece;
  margin: 1em 0;
  margin-right: 2.5%;
  margin-left: 2.5%;
  padding: 0;
}

.distance-selected-text{
  font-size: var(--text-size-fourteen);
}