

.user-corosoul h3 {
    font-size: 2rem;
    font-weight: 900;
    /* margin-bottom: 3rem; */
    color: var(--primary-color);
  }

.slick-slider button::before{
    color: grey;
    font-size: 1.5rem;
}

.user-corosoul img{
  height: 220px;
}
.user-corosoul .card-body{
  height: 120px;
}

@media screen and (max-width:768px) {
  .user-corosoul img{
    height: 190px;
  }
  .user-corosoul .card-body{
    height: 95px;
  }
  
.card-title{
    font-size: 14px;
  }
}

.text-size{
  font-size: var(--text-size-fourteen)
}

.slick-slider .slick-slide{
  overflow: auto;
}


.slick-slider .slick-prev{

}

@media screen and (max-width:400px) {
  h5{
    font-size: 14px;
  }
  .card-text{
    font-size: 13px;
  }
  .user-corosoul img{
    height: 175px;
  }
  .user-corosoul .card-body{
    height: 95px;
  }
}

@media screen and (max-width:356) {
  h5{
    font-size: 12px;
  }
  .card-text{
    font-size: 13px;
  }
  .user-corosoul img{
    height: 175px;
  }
  .user-corosoul .card-body{
    height: 95px;
  }
}

.card-text{
  font-size: var(--text-size-fourteen)
}

.card-title{
  font-size: var(--text-size-fourteen)
}

.userview-card{
  /* add styles if needed */
}

.userview-card:hover {
  transform: scale(1.04);
}

.userview-icon{
  margin-right: 0.15rem;
}

.userview-text{
  margin-right: 0.5rem;
}