.commonlisting{
    background-color: #FAFAFA;
    margin-top: 6%;
  }
.commonlisting .commonlistingContainer{
    width: 100%;
    padding-top: 1.5%;
}
.commonlisting  .headingTitle{
    padding-top: 2%;
    text-align: center;
  }
  .commonlisting .headingcontent{
    color: (--dark-grey );
    font-weight: 500 ;
    font-size: 120%;
  }
  
  .listingdisplay .subheading{
    font-weight: 500;
    font-size: 100%;
  }
 /* .commonlisting .liststyle ul li::before {
      content: "• ";
      color: #cfcece; 
    } */
  
   /* .commonlisting .liststyle ul li{
      color: #212121;
      font-weight: 500;
      font-size: 0.9em;
      font-family: Arial, Helvetica, sans-serif;
    } */
  
   .commonlisting .maincontent{
      color: var(--dark-grey);
      font-weight: 500 ;
      font-size: 100%;
    }
   .commonlisting .maincontent:hover{
      color: var(--primary-color);
    } 
    .imagebg{
      background-color:var(--white-color);
      max-width: 110%;
      max-height: 10rem;
      object-fit: contain;
      border-radius: 4px;
    }
    .imagebgMobile{
      background-color:var(--white-color);
      max-width: 100%;
      max-height: 10rem;
      object-fit: contain;
      border-radius: 4px;
    }
   
    .headingcontent:hover{
      color:var(--primary-color)
    }
  
 .commonlisting .sortinghead ul {
      list-style: none;
    }
    
   .commonlisting .sortinghead li {
      display: inline-block;
      margin-right: 3%; 
  
    }
 .commonlisting .tenantprofile{
   
  }
 .commonlisting .sortinghead ul li a {
      color:var(--black-color);
      font-weight: 400;
      font-size: medium;
    }
 .commonlisting .sortinghead ul li a:hover{
    color:var(--primary-color)
  }
 .commonlisting .showresultheading{
      font-weight: 500;
      color: var(--black-color);
      padding-left: 1.4rem;
      padding-top: 0.5rem;
  }

 
  .filter .adjustment{
    margin-left: 1px;
    margin-right: 1px;
  }
  .filter .verticalLine{
    margin-top: 10px;
    margin-bottom: 5px;
      border-left: 2px solid gray;
      height: 45px;
      font-weight: 400;
     

  }
  .main .sortinghead .sortingSubHeading{
    font-weight: 600;
    font-size: 105%;
    color: var(--primary-color);
  }
  
  .span-address-bold {
    font-weight: bold;
    color: var(--primary-color);
  }

  .filter-text-h3{
    font-size: 1rem;
    font-weight: bold;
  }

  @media screen and (max-width: 767px){
    .commonlisting .commonlistingContainer{
      padding-top: 1.8rem;
  }
}


.commonlisting-card{
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.9); 
}

.commonlisting-card:hover {
  transform: scale(1.02);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}
/* 
.commonlisting-card-image img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out;
}

.commonlisting-card-image:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.commonlisting-card-image:hover img {
  transform: scale(1.05);
} */

.filter-border {
  /*border: 0.063rem solid var(--black-color); /* Adjust the color and width as needed */
  /*padding: 0.3rem;  Optional: Add padding inside the border */
  /* border-radius: 0.313rem;  */
}

.reset-filter-btn {
  border: 0.063rem solid var(--black-color); /* Adjust the color and width as needed */
  padding: 0.3rem; /* Optional: Add padding inside the border */
  border-radius: 0.313rem; 
  background-color: var(--secondry-color);
  color: var(--black-color);
}
