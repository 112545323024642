.membership{
    margin-top: 5%;
    background-color:#FAFAFA;
    padding: 30px 0
}

.membership .containermembership{
    margin-left: 5%;
    margin-right: 5%;
}
.membership-text{
    color: var(--magic-green);
}
.membership .subContent{
    color: #282828;
    padding-left: 8%;
    padding-right: 5%;
    padding-bottom: 1%;
}

.membership a {
    text-decoration: none;
}

.membership .pricingTable {
    text-align: center;
    background: #fff;
    margin: 0 -15px;
    box-shadow: 0 0 10px #ababab;
    padding-bottom: 40px;
    border-radius: 10px;
    color: #cad0de;
    transform: scale(1);
    transition: all .5s ease 0s
}

.membership .pricingTable:hover {
    transform: scale(1.05);
    z-index: 1
}

.membership .pricingTable .pricingTable-header {
    padding: 40px 0;
    background: #f5f6f9;
    border-radius: 10px 10px 50% 50%;
    transition: all .5s ease 0s
}

.membership  .pricingTable:hover .pricingTable-header {
    background: #ff9624
}

.membership  .pricingTable .pricingTable-header i {
    font-size: 50px;
    color: #858c9a;
    margin-bottom: 10px;
    transition: all .5s ease 0s
}

.membership  .pricingTable .price-value {
    font-size: 35px;
    color: #ff9624;
    transition: all .5s ease 0s
}

.membership  .pricingTable .month {
    display: block;
    font-size: 14px;
    color: #cad0de
}

.membership  .pricingTable:hover .month,
.membership  .pricingTable:hover .price-value,
.membership  .pricingTable:hover .pricingTable-header i {
    color: #fff
}

.membership  .pricingTable .heading {
    font-size: 24px;
    color: #ff9624;
    margin-bottom: 20px;
    text-transform: uppercase
}

.membership  .pricingTable .pricing-content ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px
}

.membership  .pricingTable .pricing-content ul li {
    line-height: 30px;
    color: #a7a8aa
}

.membership  .pricingTable .pricingTable-signup a {
    display: inline-block;
    font-size: 15px;
    color: #fff;
    padding: 10px 35px;
    border-radius: 20px;
    background: #ffa442;
    text-transform: uppercase;
    transition: all .3s ease 0s
}

.membership  .pricingTable .pricingTable-signup a:hover {
    box-shadow: 0 0 10px #ffa442
}

.membership  .pricingTable.blue .heading,
.membership  .pricingTable.blue .price-value {
    color: #4b64ff
}

.membership  .pricingTable.blue .pricingTable-signup a,
.membership  .pricingTable.blue:hover .pricingTable-header {
    background: #4b64ff
}

.membership  .pricingTable.blue .pricingTable-signup a:hover {
    box-shadow: 0 0 10px #4b64ff
}

.membership  .pricingTable.red .heading,
.membership  .pricingTable.red .price-value {
    color: #ff4b4b
}

.membership  .pricingTable.red .pricingTable-signup a,
.membership  .pricingTable.red:hover .pricingTable-header {
    background: #ff4b4b
}

.membership .pricingTable.red .pricingTable-signup a:hover {
    box-shadow: 0 0 10px #ff4b4b
}

.membership .pricingTable.green .heading,
.membership .pricingTable.green .price-value {
    color: #40c952
}

.membership .pricingTable.green .pricingTable-signup a,
.membership .pricingTable.green:hover .pricingTable-header {
    background: #40c952
}

.membership .pricingTable.green .pricingTable-signup a:hover {
    box-shadow: 0 0 10px #40c952
}

.membership  .pricingTable.blue:hover .price-value,
.membership  .pricingTable.green:hover .price-value,
.membership  .pricingTable.red:hover .price-value {
    color: #fff
}

@media screen and (max-width:990px) {
    .pricingTable {
        margin: 0 0 20px
    }
}